export const logo = {
  image: `${process.env.PUBLIC_URL}/assets/images/logo.svg`,
};

export const welcome = {
  slogan: "Create, Design,",
  sloganspan: "Nest",
  topLine: "Strategie + Branding + Design + Development",
  span: "Doeltreffend digitaliseren met NetNest,",
  description: "de bestemming waar webdromen werkelijkheid worden.",
};

export const intros = {
  contact: {
    label: "Contact",
    h2: "Okay, we’ve shown (almost) everything. Are you ready to chat about a new partnership?",
    pSentence1: "Fill out the form below or send us an email at ",
    mail: "info@netnest.com",
    pSentence2: " to set up an introductory call",
  },
  work: {
    label: "Work",
    h2: "Curious about our success stories? Discover here some of our cases for web design, rebranding, ...",
  },
};

export const services = {
  h2: "Our services",
  description:
    "We invite you to explore our services and contact us to discuss your needs and ideas. We are here to help you make your dream a reality.",
  btnText: "Get in touch!",
  service1: {
    name: "Strategy",
    description: {
      p: "Strategy is the foundation of every project. It involves defining goals, understanding the target audience, and planning the approach to achieve success.",
      ul: [
        {
          li: {
            span: "Objectives and KPIs:",
            text: "Identify what the client wants to achieve with the website. These can be sales targets, lead generation, brand awareness, or user engagement. Define measurable KPIs to track progress and success.",
          },
        },
        {
          li: {
            span: "Market Research:",
            text: "Analyze the market, competitors, and target audience. Understand the needs, pain points, and behaviors of the end users to develop a strategy that truly resonates.",
          },
        },
        {
          li: {
            span: "Content Strategy:",
            text: "Plan the content that will be on the website. This includes the structure, tone of voice, and messaging that supports the client's goals and appeals to the target audience.",
          },
        },
        {
          li: {
            span: "SEO and Digital Marketing:",
            text: "Integrate search engine optimization (SEO) and other digital marketing tactics to ensure the website is easily discoverable and attracts traffic.",
          },
        },
      ],
    },
  },
  service2: {
    name: "Branding",
    description: {
      p: "Branding is how a company visually and communicatively presents itself to the world. It involves creating a consistent and recognizable identity.",
      ul: [
        {
          li: {
            span: "Visual Identity:",
            text: "Develop a logo, color scheme, typography, and other visual elements that represent the brand. These elements should be consistent and make a strong impression on visitors.",
          },
        },
        {
          li: {
            span: "Brand Message and Values:",
            text: "Define the core message and values of the brand. What does the brand want to convey? What are its core values and promises to the customer?",
          },
        },
        {
          li: {
            span: "Tone of Voice:",
            text: "Determine the communication style of the brand. Is it formal, informal, playful, business-like? This style should be consistent across all content and communication.",
          },
        },
        {
          li: {
            span: "Brand Guidelines:",
            text: "Create guidelines that describe the brand identity and how it should be applied across different media, including the website.",
          },
        },
      ],
    },
  },
  service3: {
    name: "Design",
    description: {
      p: "Design is about translating strategy and branding into an attractive and functional user interface (UI) and user experience (UX).",
      ul: [
        {
          li: {
            span: "UX Design:",
            text: "Create wireframes and prototypes that define the user flow and structure of the website. Focus on usability, intuitive navigation, and a seamless experience for the user.",
          },
        },
        {
          li: {
            span: "UI Design:",
            text: "Design the visual aspects of the website, including layouts, colors, typography, and images. Ensure that the design is not only appealing but also aligned with the brand identity.",
          },
        },
        {
          li: {
            span: "Responsive Design:",
            text: "Ensure the website works well on various devices and screen sizes, from desktops to smartphones.",
          },
        },
        {
          li: {
            span: "Interactivity and Animation:",
            text: "Add interactive elements and animations to enhance the user experience and make the website more dynamic.",
          },
        },
      ],
    },
  },
  service4: {
    name: "Development",
    description: {
      p: "Development is the technical realization of the website. It involves building a functional, efficient, and secure site.",
      ul: [
        {
          li: {
            span: "Front-end Development:",
            text: "Convert the design into HTML, CSS, and JavaScript. Ensure that the site not only looks good but also runs smoothly and quickly.",
          },
        },
        {
          li: {
            span: "Back-end Development:",
            text: "Back-end Development: Develop the server-side of the website, including databases, server logic, and APIs. Ensure a robust and scalable architecture.",
          },
        },
        {
          li: {
            span: "Content Management System (CMS):",
            text: "Content Management System (CMS): Implement and configure a CMS (such as WordPress, Drupal, etc.) so that the client can easily manage and update content.",
          },
        },
        {
          li: {
            span: "Testing and Optimization:",
            text: "Testing and Optimization: Thoroughly test the website on different devices and browsers. Perform performance optimization to minimize load times and speed up the site.",
          },
        },
        {
          li: {
            span: "Maintenance and Updates:",
            text: "Provide ongoing support and maintenance to keep the website up-to-date and secure, with regular updates and adjustments post-launch.",
          },
        },
      ],
    },
  },
};

export const work = {
  h2: "Our work",
  case1: {
    name: "Metejoor",
    intro: "Webdesign voor de band Metejoor.",
    description:
      "Metejoor, until two years ago still the enthusiastic teacher Joris Van Rossem, is incredibly popular and has been on the charts for several years. With hits such as '1 In A Million,' 'Rendezvous,' 'This Is What My Mama Said' and 'Let's Have a Flower,' he is enjoying an impressive career.",
    goal: "Metejoor was eager to gain a stronger digital presence through a revamped website that is both simple and flexibly adaptable.",
    resulth2: "A user-friendly, easy maintainable website",
    result:
      "The response we received after the initial launch of his new website was huge.",
    keyResults: {
      users: "+109K",
      views: "+270K",
    },
    image: `${process.env.PUBLIC_URL}/assets/images/cases/persons/Metejoor.jpg`,
    image2: `${process.env.PUBLIC_URL}/assets/images/cases/persons/Metejoor2.jpg`,
    mockup: `${process.env.PUBLIC_URL}/assets/images/cases/mockups/mockupMetejoor.png`,
    skills: ["Strategy", "Branding", "Design", "Development"],
    link: "https://metejoor.be/",
    dataPos: -2,
  },
  case2: {
    name: "5napback",
    intro: "Webdesign for 5napback.",
    description:
      "With 8 years of experience and as winner of MNM Start to DJ, he is an established name in the music world music scene. With 4 successful MNM Big Hits and more than 9 million streams, he enchants listening Flanders. He also shines at festivals like Tomorrowland and Balaton and as the youngest member of the 3-piece Bromeo's.",
    goal: "5napback had als doel een vernieuwde website te ontwikkelen die zowel gebruiksvriendelijk als aanpasbaar is, met als doel zijn online aanwezigheid te versterken en een groter publiek te bereiken.",
    resulth2: "A user-friendly, easy maintainable website",
    result:
      "Since the launch of the new website, 5napback has experienced a significant increase in booking requests, demonstrating that my work has made an impact and contributed to its success.",
    keyResults: {
      users: "+2,2K",
      views: "+2,5K",
    },
    image: `${process.env.PUBLIC_URL}/assets/images/cases/persons/5NAPBACK.jpg`,
    image2: `${process.env.PUBLIC_URL}/assets/images/cases/persons/5NAPBACK2.jpg`,
    mockup: `${process.env.PUBLIC_URL}/assets/images/cases/mockups/mockup5NAPBACK.png`,
    skills: ["Strategy", "Branding", "Design", "Development"],
    link: "https://5napback.com/",
    dataPos: -1,
  },
  case3: {
    name: "llllfour",
    intro: "Webdesign for llllfour.",
    description:
      "Femke, Gloria, Lina and Marie-Emilie dream of a career as a singer. They impressed during 'The Voice Kids,' now together they form IIII, a new girl group that brings contemporary pop music with influences from their favorite artists.",
    goal: "Their website aimed to promote their emerging girl group and let people discover more about who they are.",
    resulth2: "A user-friendly, easy maintainable website",
    result:
      "People are quickly finding their way to their website, and their summer is already busy with gigs throughout Flanders.",
    keyResults: {
      users: "+2,2K",
      views: "+2,5K",
    },
    image: `${process.env.PUBLIC_URL}/assets/images/cases/persons/llllfour.jpeg`,
    image2: `${process.env.PUBLIC_URL}/assets/images/cases/persons/llllfour2.jpg`,
    mockup: `${process.env.PUBLIC_URL}/assets/images/cases/mockups/mockupllllfour.png`,
    skills: ["Strategy", "Branding", "Design", "Development"],
    link: "https://llllfour.be/",
    dataPos: 0,
  },
  case4: {
    name: "LEEZ",
    intro: "Webdesign for Leez.",
    description:
      "Lisa Van Rossem, the sister and backing vocal of singer Metejoor. She recently broke out with her first solo single “Who will be there”, now it's time to introduce her brand new website.",
    goal: "Lisa aimed for her website to create a central hub where she could share her reels, videos and performances, allowing her fans to feel a deeper connection to her work.",
    resulth2: "A user-friendly, easy maintainable website",
    result:
      "Lisa uses her website weekly to share her reels online and is extremely satisfied with the customizable design.",
    keyResults: {
      users: "+1,1K",
      views: "+1,8K",
    },
    image: `${process.env.PUBLIC_URL}/assets/images/cases/persons/LEEZ.jpg`,
    image2: `${process.env.PUBLIC_URL}/assets/images/cases/persons/LEEZ2.jpg`,
    mockup: `${process.env.PUBLIC_URL}/assets/images/cases/mockups/mockupLEEZ.png`,
    skills: ["Strategy", "Branding", "Design", "Development"],
    link: "https://leezofficial.be/",
    dataPos: 1,
  },
  case5: {
    name: "MANUALS",
    intro: "Webdesign for MANUALS.",
    description:
      "Manuals is a dynamic EDM producer and DJ who performs at major festivals and clubs worldwide, with music that can also be heard on national radio stations.",
    goal: "The goal of Manuals' website is to provide his fans and new listeners with information about his music, performances, and recent releases.",
    resulth2: "A user-friendly, easy maintainable website",
    result:
      "On his website and social media, Manuals always uses the same style, colors and logos. That way, people immediately know it's his. That makes his brand strong and easily recognizable to his fans.",
    keyResults: {
      users: "+1,1K",
      views: "+1,8K",
    },
    image: `${process.env.PUBLIC_URL}/assets/images/cases/persons/MANUALS.jpg`,
    image2: `${process.env.PUBLIC_URL}/assets/images/cases/persons/MANUALS2.jpg`,
    mockup: `${process.env.PUBLIC_URL}/assets/images/cases/mockups/mockupMANUALS.png`,
    skills: ["Strategy", "Branding", "Design", "Development"],
    link: "https://manualsmusic.be/",
    dataPos: 2,
  },
  case6: {
    name: "Showscape",
    intro: "Webdesign for Showscape.",
    description:
      "Showscape is responsible for the management of some major artists from Belgium, including Michael Schack and Manuals.",
    goal: "The objective of Showscape's website was to inform potential clients, such as artists, festivals, and event organizers, about their artist management services and expertise.",
    resulth2: "A user-friendly, easy maintainable website",
    result:
      "Thanks to their new website, Showscape is becoming more and more well-known. On the site, people can find everything about what they do and which artists they represent. This helps them stand out even more in the music world.",
    keyResults: {
      users: "+1,1K",
      views: "+1,8K",
    },
    image: `${process.env.PUBLIC_URL}/assets/images/cases/persons/Showscape.jpg`,
    image2: `${process.env.PUBLIC_URL}/assets/images/cases/persons/Showscape2.jpg`,
    mockup: `${process.env.PUBLIC_URL}/assets/images/cases/mockups/mockupShowscape.png`,
    skills: ["Strategy", "Branding", "Design", "Development"],
    link: "https://showscape.be/",
  },
};

export const jealous = {
  h2: "Jealous?",
  description:
    "A user-friendly, easy-to-maintain website, beautifully nested with NetNest. Metejoor has found his perfect digital home with us, and you can too. Would you like to create your own nest with us, just like Metejoor? Reach out and contact us today to start building your digital presence!",
  btnText: "Get in touch!",
};

export const partners = {
  h2: "Our partners",
  metejoor: {
    image: "assets/images/partners/Metejoor.svg",
  },
  oneBookings: {
    image: "assets/images/partners/5NAPBACK.svg",
  },
  SNapback: {
    image: "assets/images/partners/MANUALS.svg",
  },
  LEEZ: {
    image: "assets/images/partners/LEEZ.svg",
  },
  fruitylabel: {
    image: "assets/images/partners/OneBookings.svg",
  },
  Manuals: {
    image: "assets/images/partners/Showscape.svg",
  },
};

export const team = {
  h2: "Meet the team",
  Jonas: {
    image: "assets/images/team/Jonas.jpg",
    role: "CEO, Sales Manager",
  },
  Sieme: {
    image: "assets/images/team/Sieme.jpg",
    role: "Project Manager, Marketing Manager",
  },
  Persoon: {
    image: "assets/images/team/Sieme.jpg",
    role: "CEO, Sales Manager",
  },
};

export const stats = {
  h2: "Net",
  span: "Nest",
  h22: "In Numbers",
  stat1: {
    number: "2+",
    description: "Collective years of experience",
  },
  stat2: {
    number: "3",
    description: "Team members",
  },
  stat3: {
    number: "1",
    description: "Sectors we’ve innovated in",
  },
  stat4: {
    number: "6",
    description: "Partner since we formed",
  },
  stat5: {
    number: "6",
    description: "Projects we’ve completed",
  },
  stat6: {
    number: "450K+",
    description: "Users on products we’ve designed",
  },
  image: `${process.env.PUBLIC_URL}/assets/images/cases/persons/Metejoor2.jpg`,
};

export const questions = {
  h2: "Curious to know more?",
  description:
    "Over time we have collated the most frequently asked questions from new partners. If there is something that is not in this list, please use the form above or drop us a line at info@evolution.com.",
  question1: {
    question: "What services do you offer?",
    answer:
      "NetNest offers a comprehensive range of services to help your brand thrive. Our expertise covers four core areas: Strategy, Branding, Design and Development.",
  },
  question2: {
    question: "How much does a website cost?",
    answer:
      "The price of your website depends on a huge number of factors. How comprehensive do you want your website to be? Do you need different functionalities? Maybe a blog is interesting for you or maybe you need a web shop? During an intake we ask specific questions and advise you which type of website and which package is most appropriate to achieve the best results. ?",
  },
  question3: {
    question: "What does your design proces look like?",
    answer:
      "At NetNest, we follow a well-defined, yet flexible design process that ensures a smooth and successful project from start to finish. For more details about our design process, we invite you to visit our AboutUs page. Here you will find a comprehensive overview of how we approach our various design projects.",
  },
  question4: {
    question: "How long does it take to complete a typical project?",
    answer:
      "At the start of your project, you will be given a deadline by which we will put the website live. Usually we deliver a website within 6 weeks. Still need it faster? If our schedule allows it, we are more than happy to be flexible. ",
  },
  question5: {
    question: "Do you offer revisions throughout the project lifecycle?",
    answer:
      "Yes! We value client satisfaction and believe in effective collaboration throughout the project lifecycle. We offer revisions and incorporate your feedback at different stages of the design process to ensure that the final deliverable meets your expectations. We typically allow for a certain number of revision rounds as part of our design process. The number of revisions may vary depending on the project scope and agreed-upon terms. However, we maintain open and transparent communication, and if you require additional revisions beyond the initial rounds, we are always happy to discuss them and accommodate based on your project's needs. Our goal is to deliver designs that align with your vision, and we welcome your input throughout the project.",
  },
  question6: {
    question: "How involved will I be in the design process?",
    answer: {
      sentence1:
        "We believe in fostering a collaborative partnership with our clients throughout the design process. Your involvement is crucial, as your insights, feedback, and vision are valuable in shaping the final design outcome.",
      sentence2:
        "We value your expertise and understanding of your business or project goals. To ensure that the design accurately reflects your vision and brand identity, we actively involve you in key stages of the design process. These stages may include initial consultations, concept presentations, design reviews and feedback cycles.",
      sentence3:
        "During these stages we encourage you to provide input, share your ideas, and provide feedback on the design concepts and iterations. Your involvement enables us to iteratively refine and tailor the design to align with your preferences and objectives.",
    },
  },
};
