import React, { useState } from "react";

function Question({ question, answer }) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <div className="question" onClick={handleClick}>
      <div className={`top ${clicked ? "clicked" : ""}`}>
        <h4>{question}</h4>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/${
            clicked ? "min-circle.svg" : "plus-circle.svg"
          }`}
          alt={clicked ? "Min Circle Icon" : "Plus Circle Icon"}
        />
      </div>
      {clicked && (
        <div className="answer">
          {typeof answer === "object" ? (
            Object.values(answer).map((sentence, index) => (
              <p key={index}>{sentence}</p>
            ))
          ) : (
            <p>{answer}</p>
          )}
        </div>
      )}
    </div>
  );
}

export default Question;
