import React from "react";
import Button from "../components/Button";

function Jealous({ h2, description, btnText }) {
  return (
    <>
      <section className="jealous-section">
        <h2>{h2}</h2>
        <p>{description}</p>
        <Button btnText={btnText} />
      </section>
    </>
  );
}

export default Jealous;
