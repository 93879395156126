import React from "react";
import Button from "../components/Button";

function SayHello({ btnText }) {
  return (
    <>
      <section className="contact-section">
        <h2>Let's get started...</h2>
        <form>
          <div className="projectOrGeneral">
            <p>
              Project <span>/ General</span>
            </p>
            <div className="briefUsWhatYouNeed">
              <p>Brief us on what you need...</p>
              <div className="subjects">
                <p>Website</p>
                <p>Mobile App</p>
                <p>Desktop App</p>
                <p>Experimental</p>
                <p>Strategy</p>
                <p>Experience Design</p>
                <p>Visual Design</p>
                <p>3D Design</p>
                <p>Motion</p>
                <p>Branding</p>
                <p>Illustration</p>
                <p>Workshops</p>
                <p>Other</p>
              </div>
            </div>
            <div className="introduceYourself">
              <p>Introduce yourself</p>
              <div className="column">
                <label>Full name*</label>
                <input placeholder="Your full name"></input>
              </div>
              <div className="column">
                <label>Email*</label>
                <input placeholder="Your email adress"></input>
              </div>
              <div className="column">
                <label>Company</label>
                <input placeholder="Your company name"></input>
              </div>
              <div className="column">
                <label>Website</label>
                <input placeholder="Your website"></input>
              </div>
              <div className="column">
                <label>Your Enquiry*</label>
                <textarea placeholder="Enter your message here"></textarea>
              </div>
              {/* <p>
                Please read our <a href="#">privacy policy</a> and{" "}
                <a href="#">cookies policy</a> so you understand how we collect
                and use your personal data.
              </p> */}

              <Button btnText={btnText} />
            </div>
          </div>
        </form>
      </section>
    </>
  );
}

export default SayHello;
