import React from "react";
import Question from "../components/Question";

function Questions({ h2, description, questions }) {
  const filteredQuestions = Object.entries(questions).filter(([key, value]) =>
    key.startsWith("question")
  );

  return (
    <section className="questions-section">
      <h2>{h2}</h2>
      <p>{description}</p>
      <div className="questions">
        {filteredQuestions.map(([questionName, question]) => (
          <Question
            key={questionName}
            question={question.question}
            answer={question.answer}
          />
        ))}
      </div>
    </section>
  );
}

export default Questions;
