export function enableHorizontalScroll() {
  window.addEventListener("load", function () {
    var teamMembersContainer = document.querySelector(".teamMembers");
    var scrollAmount = 3; // Verlaagde scrollhoeveelheid naar 3 pixels per keer
    var scrollInterval = 30; // Verhoogd scrollinterval naar 30 milliseconden

    function scrollHorizontally() {
      teamMembersContainer.scrollLeft += scrollAmount;
    }

    var scrollIntervalId = setInterval(scrollHorizontally, scrollInterval);

    // Stop automatisch scrollen als de muis over de container beweegt
    teamMembersContainer.addEventListener("mouseenter", function () {
      clearInterval(scrollIntervalId);
    });

    // Start automatisch scrollen opnieuw als de muis de container verlaat
    teamMembersContainer.addEventListener("mouseleave", function () {
      scrollIntervalId = setInterval(scrollHorizontally, scrollInterval);
    });
  });
}
