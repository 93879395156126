import React, { useEffect } from "react";
import TeamMember from "../components/TeamMember";
import { enableHorizontalScroll } from "../scripts/scroll";

function Team({ h2, teamMembers }) {
  useEffect(() => {
    enableHorizontalScroll();
  }, []);

  return (
    <section className="team-section">
      <h2>{h2}</h2>
      <div className="teamMembers">
        {Object.entries(teamMembers).map(([teamMemberName, teamMemberData]) => {
          if (teamMemberName !== "h2") {
            return (
              <TeamMember
                key={teamMemberName}
                name={teamMemberName}
                role={teamMemberData.role}
                image={teamMemberData.image}
              />
            );
          }
          return null;
        })}
      </div>
    </section>
  );
}

export default Team;
