import React from "react";

function PageIntro({ label, h2, pSentence1, mail, pSentence2 }) {
  return (
    <>
      <section className="pageIntro-section">
        <span>{label}</span>
        <h2>{h2}</h2>
        <p>
          {pSentence1}
          <span>{mail}</span>
          {pSentence2}
        </p>
      </section>
    </>
  );
}

export default PageIntro;
