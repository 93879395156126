import React from "react";

function Footer({}) {
  return (
    <>
      <footer>
        <div className="logo"></div>
        <div className="links">
          <div className="menu">
            <h3>Menu</h3>
            <a href="#">Home</a>
            <a href="#">Work</a>
            <a href="../pages/AboutUs.js">About</a>
          </div>
          <div className="socials">
            <h3>Socials</h3>
            <a href="#">Instagram</a>
            <a href="#">Facebook</a>
            <a href="#">LinkedIn</a>
          </div>
        </div>
        <p className="border"></p>
        <p>
          © 2024, Net<span>Nest.</span> All Rights Reserved.
        </p>
        <div className="backToTop">
          <p>Back to top</p>
          <i className="fa fa-arrow-up"></i>
        </div>
      </footer>
    </>
  );
}

export default Footer;
