import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";

function Navigation({ logo }) {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const closeMenu = () => {
    setMenuActive(false);
  };

  return (
    <nav className={`mobileNav ${menuActive ? "fixed" : ""}`}>
      <div className="top">
        {logo && <Logo image={logo} />}
        <p onClick={toggleMenu}>{menuActive ? "Close" : "Menu"}</p>
      </div>
      <div className={`mobileMenu ${menuActive ? "active" : ""}`}>
        <div className="links">
          <Link to="/" onClick={closeMenu}>
            <div className="mobileMenuItem">
              <span>Our Story</span>
              <h1>Home</h1>
            </div>
          </Link>
          <Link to="/work" onClick={closeMenu}>
            <div className="mobileMenuItem">
              <span>Cases</span>
              <h1>Work</h1>
            </div>
          </Link>
          <Link to="/AboutUs" onClick={closeMenu}>
            <div className="mobileMenuItem">
              <span>Who We Are</span>
              <h1>About</h1>
            </div>
          </Link>
          <Link to="/contact" onClick={closeMenu}>
            <div className="mobileMenuItem">
              <span>Get in Touch</span>
              <h1>Contact</h1>
            </div>
          </Link>
        </div>
        <div className="socials">
          <p>Follow our journey</p>
          <div>
            <a
              href="https://www.instagram.com/netnest.be/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61560265021807"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
