import React from "react";
import Work from "../sections/Work";
import SayHello from "../sections/SayHello";
import Footer from "../components/Footer";
import { work } from "../components/Data";

function AboutUs() {
  return (
    <>
      <Work h2={work.h2} cases={work} />
      <SayHello />
      <Footer />
    </>
  );
}

export default AboutUs;
