import React from "react";
import Welcome from "../sections/Welcome";
import Carousel from "../sections/Carousel";
import Partners from "../sections/Partners";
import SayHello from "../sections/SayHello";
import Footer from "../components/Footer";
import { logo } from "../components/Data";
import { welcome } from "../components/Data";
import { work } from "../components/Data";
import { partners } from "../components/Data";

// In de parent component
const convertWorkToArray = (work) => {
  return Object.keys(work)
    .filter((key) => key !== "h2")
    .map((key) => [key, work[key]]);
};

const images = convertWorkToArray(work);

function Home() {
  return (
    <>
      <Welcome
        logo={logo.image}
        slogan={welcome.slogan}
        sloganspan={welcome.sloganspan}
        topLine={welcome.topLine}
        span={welcome.span}
        description={welcome.description}
      />
      <Carousel h2={work.h2} images={images} />
      <Partners h2={partners.h2} images={partners} />
      <SayHello />
      <Footer />
    </>
  );
}

export default Home;
