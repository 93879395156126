import React from "react";
import Service from "../components/Service";
import Button from "../components/Button";

function Services({ h2, description, services, btnText }) {
  const filteredServices = Object.entries(services).filter(([key, value]) =>
    key.startsWith("service")
  );

  return (
    <>
      <section className="services-section">
        <div className="top">
          <h2>{h2}</h2>
          <div className="info">
            <p>{description}</p>
            <Button btnText={btnText} />
          </div>
        </div>
        <div className="services">
          {filteredServices.map(([serviceName, serviceData]) => (
            <Service
              key={serviceName}
              name={serviceData.name}
              descriptionP={serviceData.description.p}
              descriptions={serviceData.description.ul.map((item) => ({
                span: item.li.span,
                text: item.li.text,
              }))}
            />
          ))}
        </div>
      </section>
    </>
  );
}

export default Services;
