import React from "react";
import Logo from "../components/Logo";

function Welcome({ logo, slogan, sloganspan, topLine, span, description }) {
  return (
    <section className="welcome-section">
      <div className="visibility">
        <p className="topLine">{topLine}</p>
        <p>
          <span>{span} </span>
          {description}
        </p>
      </div>
      <div className="center">
        {logo && <Logo image={logo} />}{" "}
        <p>
          {slogan}
          <span> {sloganspan}</span>
        </p>
      </div>
      <div className="text">
        <p className="topLine">{topLine}</p>
        <p>
          <span>{span} </span>
          {description}
        </p>
      </div>
    </section>
  );
}

export default Welcome;
