import React, { useState, useEffect, useCallback } from "react";
import Case from "../components/Case";

function Carousel({ h2, images: initialImagesState }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [images, setImages] = useState(initialImagesState);

  const handleItemClick = useCallback(
    (index) => {
      setActiveIndex(index);
      if (Array.isArray(images)) {
        const updatedImages = images.map(([caseName, caseData], i) => {
          return [
            caseName,
            { ...caseData, dataPos: i === index ? 0 : i - index },
          ];
        });
        setImages(updatedImages);
      }
    },
    [images, setImages]
  );

  useEffect(() => {
    const handleClick = (e) => {
      const carouselItem = e.target.closest(".carousel__item");
      if (carouselItem) {
        const index = Array.from(carouselItem.parentNode.children).indexOf(
          carouselItem
        );
        handleItemClick(index);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleItemClick]);

  return (
    <section className="carousel">
      <h2>{h2}</h2>
      <p>These satisfied customers went before you</p>

      <ul className="carousel__list">
        {images.slice(0, 5).map(([caseName, caseData], index) => (
          <Case
            key={caseName}
            name={caseData.name}
            image={caseData.image}
            className={`carousel__item ${
              index === activeIndex ? "carousel__item_active" : ""
            }`}
            dataPos={caseData.dataPos}
            onClick={() => handleItemClick(index)}
          />
        ))}
      </ul>
    </section>
  );
}

export default Carousel;
