import React from "react";

function Button({ btnText }) {
  return (
    <div className="btn">
      <p>{btnText}</p>
      <i className="fa fa-arrow-right"></i>
    </div>
  );
}

export default Button;
