import React, { useState } from "react";

function Service({ name, descriptions }) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <div className={"service"} onClick={handleClick}>
      <div className={`top ${clicked ? "clicked" : ""}`}>
        <h4>{name}</h4>
        <i className={`fa ${clicked ? "fa-arrow-up" : "fa-arrow-down"}`}></i>
      </div>
      {clicked && (
        <div className="description">
          {descriptions.map((item, index) => (
            <ul key={index}>
              <li>
                <span>{item.span} </span>
                {item.text}
              </li>
            </ul>
          ))}
        </div>
      )}
    </div>
  );
}

export default Service;
