import React from "react";
import PageIntro from "../components/PageIntro";
import ContactForm from "../sections/ContactForm";
import Questions from "../sections/Questions";
import Footer from "../components/Footer";
import { intros, questions } from "../components/Data";

function Contact() {
  const { label, h2, pSentence1, mail, pSentence2 } = intros.contact;
  return (
    <>
      <PageIntro
        label={label}
        h2={h2}
        pSentence1={pSentence1}
        mail={mail}
        pSentence2={pSentence2}
      />
      <ContactForm btnText={"send"} />
      <Questions
        h2={questions.h2}
        description={questions.description}
        questions={questions}
      />
      <Footer />
    </>
  );
}

export default Contact;
