import React from "react";
import { Link } from "react-router-dom";

function Logo({ image }) {
  return (
    <Link to={`${process.env.PUBLIC_URL}`}>
      <div className="logo" style={{ backgroundImage: `url(${image})` }}></div>
    </Link>
  );
}

export default Logo;
