import React from "react";
import Stat from "../components/Stat";

function Stats({ h2, h22, span, stats, image }) {
  return (
    <section className="stats-section">
      <h2>
        {h2}
        <span>{span} </span>
        {h22}
      </h2>
      <div className="elements">
        <div className="stats">
          {Object.entries(stats)
            .filter(([key]) => key.startsWith("stat"))
            .map(([statsKey, statsValue]) => {
              return (
                <Stat
                  key={statsKey}
                  number={statsValue.number}
                  description={statsValue.description}
                />
              );
            })}
        </div>
        <div
          className="image"
          style={{ backgroundImage: `url(${image || ""})` }}
        ></div>
      </div>
    </section>
  );
}

export default Stats;
