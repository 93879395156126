import React from "react";
import Case from "../components/Case";

function RecentWork({ h2, cases }) {
  if (!cases) return null;
  let caseCount = 0;
  return (
    <section className="cases">
      <h2>Recent work</h2>
      <div className="case">
        {Object.entries(cases).map(([caseName, caseData]) => {
          if (caseName !== "h2" && caseCount < 2) {
            caseCount++;
            return (
              <Case
                key={caseName}
                name={caseData.name}
                skills={caseData.skills}
                description={caseData.description}
                image={caseData.image}
              />
            );
          }
          return null;
        })}
      </div>
    </section>
  );
}
export default RecentWork;
