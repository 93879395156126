import React from "react";

function Partner({ name, image }) {
  return (
    <div className={`partner ${name}`}>
      <div
        className="image"
        style={{ backgroundImage: `url(${image || ""})` }}
      ></div>
    </div>
  );
}

export default Partner;
