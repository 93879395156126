import React from "react";
import Case from "../components/Case";

function Work({ h2, cases }) {
  if (!cases) return null;

  return (
    <section className="cases">
      <h2>{h2}</h2>
      <div className="case">
        {Object.entries(cases).map(([caseName, caseData]) => {
          if (caseName !== "h2") {
            return (
              <Case
                key={caseName}
                name={caseData.name}
                skills={caseData.skills}
                description={caseData.description}
                image={caseData.image}
              />
            );
          }
          return null;
        })}
      </div>
    </section>
  );
}

export default Work;
