import React from "react";
import Team from "../sections/Team";
import Stats from "../sections/Stats";
import Services from "../sections/Services";
import Partners from "../sections/Partners";
import RecentWork from "../sections/RecentWork";
import SayHello from "../sections/SayHello";
import Footer from "../components/Footer";
import { services, partners, team, stats, work } from "../components/Data";

function AboutUs() {
  return (
    <>
      <Team h2={team.h2} teamMembers={team} />
      <Services
        h2={services.h2}
        description={services.description}
        services={services}
        btnText={services.btnText}
      />
      <Stats
        h2={stats.h2}
        span={stats.span}
        h22={stats.h22}
        stats={stats}
        image={stats.image}
      />
      <Partners h2={partners.h2} images={partners} />
      <RecentWork h2={work.h2} cases={work} />
      <SayHello />
      <Footer />
    </>
  );
}

export default AboutUs;
