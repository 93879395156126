import React from "react";
import { useParams } from "react-router-dom";
import { jealous, work } from "../components/Data";
import Jealous from "../sections/Jealous";
import SayHello from "../sections/SayHello";
import Footer from "../components/Footer";
import RecentWork from "../sections/RecentWork"; // Make sure to import RecentWork

function CaseDetails() {
  const { caseName } = useParams();
  const selectedCase = Object.values(work).find(
    (item) => item.name && item.name.toLowerCase() === caseName.toLowerCase()
  );

  if (!selectedCase) {
    return <div>Case not found</div>;
  }

  return (
    <>
      <div className="caseDetails">
        <div className="info">
          <h2>{selectedCase.name}</h2>
          <p>{selectedCase.description}</p>
          {selectedCase.skills && (
            <div className="skills">
              {selectedCase.skills.map((skill, index) => (
                <div key={index}>
                  <p>{skill}</p>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="description">
          {selectedCase.skills && (
            <div className="skillsDescription">
              {selectedCase.skills.map((skill, index) => (
                <div key={index}>
                  <h4>{skill}</h4>
                </div>
              ))}
            </div>
          )}
          <p>{selectedCase.goal}</p>
          {selectedCase.link && (
            <p className="link">
              Check out the result at{" "}
              <a
                href={selectedCase.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {selectedCase.link}
              </a>
              .
            </p>
          )}
        </div>
        <div className="caseDetailsImage">
          {selectedCase.mockup && (
            <div
              className="image"
              style={{ backgroundImage: `url(${selectedCase.mockup})` }}
            ></div>
          )}
        </div>
        <div className="result">
          <h4 className="label">Result</h4>
          <h4 className="title">{selectedCase.resulth2}</h4>
          <p>{selectedCase.result}</p>
          <div className="keyResults">
            <h4>Key results</h4>
            <p>
              <span>{selectedCase.keyResults.users}</span> users
            </p>
            <p>
              <span>{selectedCase.keyResults.views}</span> views
            </p>
          </div>
          <p>
            What's next? At Net<span>Nest.</span> we make sure your{" "}
            <span>digital ambitions come to life</span>. Create, Design,{" "}
            <span>Nest</span> - that's our philosophy. {selectedCase.name} has
            now <span>found his online nest with us</span>, and we are proud to
            have been able to help him realize his digital home.
          </p>
        </div>

        <div className="caseDetailsImage">
          {selectedCase.image2 && (
            <div
              className="image"
              style={{ backgroundImage: `url(${selectedCase.image2})` }}
            ></div>
          )}
        </div>
      </div>
      <Jealous
        h2={jealous.h2}
        description={jealous.description}
        btnText={jealous.btnText}
      />
      <RecentWork h2={work.h2} cases={work} />
      <SayHello />
      <Footer />
    </>
  );
}

export default CaseDetails;
