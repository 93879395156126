import React from "react";

function TeamMember({ name, role, image }) {
  return (
    <div className={`teamMember`}>
      <div
        className="image"
        style={{ backgroundImage: `url(${image || ""})` }}
      ></div>
      <p className="name">{name}</p>
      <p className="role">{role}</p>
    </div>
  );
}

export default TeamMember;
