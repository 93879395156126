import React from "react";

function Stat({ number, description }) {
  return (
    <div className={`stat`}>
      <h1>{number}</h1>
      <p>{description}</p>
    </div>
  );
}

export default Stat;
