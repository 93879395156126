function Case({ name, image, description, skills, dataPos }) {
  const backgroundImageStyle = {
    backgroundImage: `url(${image || "default-image-url"})`,
  };

  return (
    <div className={`case carousel__item`} data-pos={dataPos}>
      <div className="case-image" style={backgroundImageStyle}></div>
      <div className="case-details">
        <h2>{name}</h2>
        {skills && (
          <div className="skills">
            {skills.map((skill, index) => (
              <div key={index}>
                <p>{skill}</p>
              </div>
            ))}
          </div>
        )}
        <p>{description}</p>
      </div>
    </div>
  );
}

export default Case;
