import React from "react";

function SayHello() {
  return (
    <>
      <section className="say-hello-section">
        <h4>Need help with your project</h4>
        <h2>Say hello!</h2>
      </section>
    </>
  );
}

export default SayHello;
